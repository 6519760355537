<h2 mat-dialog-title>Crear nuevo perfil para empresa</h2>
<mat-dialog-content class="mat-typography">
  <h3>Inserte los datos para crear un nuevo perfil</h3>

  <form [formGroup]="companyProfile" (ngSubmit)="onSubmit()">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput type="text" formControlName="name" required />
            <mat-error *ngIf="companyProfile.get('name').invalid">
              {{ getErrorMessage(companyProfile.get('name')) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
          <mat-form-field>
            <mat-label>Cif</mat-label>
            <input matInput type="text" formControlName="cif" required />
            <mat-error *ngIf="companyProfile.get('cif').invalid">
              {{ getErrorMessage(companyProfile.get('cif')) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
          <mat-form-field>
            <mat-label>Dirección</mat-label>
            <input matInput type="text" formControlName="address" required />
            <mat-error *ngIf="companyProfile.get('address').invalid">
              {{ getErrorMessage(companyProfile.get('address')) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3">
          <mat-form-field>
            <mat-label>Teléfono</mat-label>
            <input matInput type="text" formControlName="phone" required />
            <mat-error *ngIf="companyProfile.get('phone').invalid">
              {{ getErrorMessage(companyProfile.get('phone')) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">        
      <div fxFlex>
        <mat-label style='display: block; font-size: 12px; color: #666;'>Imagen</mat-label>
        <input type="file" formControlName="image" required value="Escoger imagen" (change)="onFileChange($event)" />
        <mat-error *ngIf="companyProfile.get('image').invalid">
            {{ getErrorMessage(companyProfile.get('image')) }}
        </mat-error>
      </div>
      <div fxFlex class="mt-30" *ngIf="companyProfile.get('image').value == 'not-modified'">
      </div>
      <div fxFlex class="mt-30 profilePictureWraper" *ngIf="companyProfile.get('image').value != 'not-modified'">
        <img class="profilePicture" [src]="companyProfile.get('image').value">
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button
    mat-raised-button
    (click)="onSubmit()"
    cdkFocusInitial
    color="primary"
    [disabled]=companyProfile.invalid
  >
    Crear
  </button>
</mat-dialog-actions>
