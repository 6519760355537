import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl
} from '@angular/forms';
import {
  MatSnackBar
} from '@angular/material';
import { UsersService } from '../../pages/users/users.service';

@Component({
  selector: 'app-fab-helper',
  templateUrl: './fab-helper.component.html',
  styleUrls: ['./fab-helper.component.scss']
})
export class FabHelperComponent implements OnInit {
  @Output() click: EventEmitter<MouseEvent> = new EventEmitter();

  showForm = false;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<FabHelperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private userService: UsersService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      phone: [null, Validators.required],
      commentary: [null, Validators.required],
    });
  }

  onClick(event: MouseEvent) {
    this.showForm = !this.showForm;
    this.click.emit(event);
  }

  getErrorMessage(control: AbstractControl) {
    return control.hasError('required')
      ? 'Debes ingresar un valor'
      : 'Valor no valido';
  }

  clickedOutside() {
    if (this.showForm) {
      this.showForm = false;
    }
  }

  sendEmail() {
    if (this.form.invalid) {
      return;
    }

    const data: any = this.form.getRawValue();

    this.userService.sendMessage(data).subscribe(
      r => {
        this.openSnackBar('Mensaje enviado');
      },
      e => {
        this.openSnackBar('El mensaje no pudo ser enviado');
      }
    );
  }

  openSnackBar(message: string, action = 'OK') {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

}
