import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileType, Profile } from '../../../../users/user';
import { getErrorMessage } from '../../../../../shared/getErrorMessage';
import { UserService } from '../../../../auth/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-profile-dialog',
  templateUrl: './new-profile-dialog.component.html',
  styleUrls: ['./new-profile-dialog.component.scss']
})

export class NewProfileDialogComponent implements OnInit {
  companyProfile: FormGroup;
  profileType = ProfileType;
  getErrorMessage = getErrorMessage;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewProfileDialogComponent>
  ) {
    this.companyProfile = this.fb.group({
      type: [ProfileType.business, Validators.required],
      name: [null, Validators.required],
      cif: [null, Validators.required],
      address: [null, Validators.required],
      phone: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[0-9]*$")]],
      image: [null, [Validators.required]],
      picture: [null]
    });

    this.companyProfile.patchValue({
      image: 'not-modified'
    });

    // TEST
    this.companyProfile.patchValue({
      name: '',
      cif: '',
      address: '',
      phone: ''
    });

  }

  ngOnInit() {}

  onSubmit() {
    if (this.companyProfile.invalid) {
      return;
    }

    const profile: Profile = this.companyProfile.getRawValue();

    this.userService.createProfile(profile).subscribe({
      next: () => {
        this.openSnackBar('Perfil creado.');
        this.dialogRef.close({created: true});
        this.userService.getUserProfile().subscribe();
      },
      error: () => this.openSnackBar('Error al crear perfil.')
    });
  }

  openSnackBar(message: string, action = 'OK') {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.companyProfile.patchValue({
        picture: file.name
      });
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.companyProfile.patchValue({
          image: reader.result
       });
      };
    }
  }

}
