import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private urlApi: string;

  constructor(private http: HttpClient) {
    this.urlApi = environment.api_url;
  }

  get<T>(
    endpoint: string,
    params?: { [param: string]: string | string[] },
    reqOpts?: any
  ): Observable<any> {
    if (!reqOpts) {
      reqOpts = {};
    }

    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          if (!params[key]) {
            delete params[key];
          }
        }
      }
      reqOpts.params = params;
    }

    return this.http.get<T>(this.urlApi + endpoint, reqOpts);
  }

  post<T>(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.post<T>(this.urlApi + endpoint, body, reqOpts);
  }

  put<T>(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put<T>(this.urlApi + endpoint, body, reqOpts);
  }

  delete<T>(
    endpoint: string,
    params?: { [param: string]: string | string[] },
    reqOpts?: any
  ): Observable<any> {
    if (!reqOpts) {
      reqOpts = {};
    }

    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          if (!params[key]) {
            delete params[key];
          }
        }
      }
      reqOpts.params = params;
    }
    return this.http.delete<T>(this.urlApi + endpoint, reqOpts);
  }

  patch<T>(endpoint: string, body: any, reqOpts?: any): Observable<any> {
    return this.http.put<T>(this.urlApi + endpoint, body, reqOpts);
  }

  // TODO
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(message: string) {}
}
