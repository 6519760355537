/*
export const environment = {
  production: false,
  base_url: 'http://localhost:4200',
  api_url: 'http://localhost:8080/api/',
  headerAuth: 'Authorization',
  client_id: 'testclient',
  client_secret: 'testpass'
};
*/
/*
export const environment = {
  production: false,
  base_url: 'https://carltoninversiones.com/',
  api_url: 'https://carltoninversiones.com/api/',
  headerAuth: 'Authorization',
  client_id: 'testclient',
  client_secret: 'testpass'
};
*/
export const environment = {
  production: false,
  base_url: ' https://clientes.grupociba.com/carlton',
  api_url: ' https://clientes.grupociba.com/carlton/api/',
  headerAuth: 'Authorization',
  client_id: 'testclient',
  client_secret: 'testpass'
};