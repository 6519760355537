import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackdropModule } from '../../shared/backdrop/backdrop.module';
import { LoadingIndicatorModule } from '../../shared/loading-indicator/loading-indicator.module';
import { MediaQueryService } from '../../shared/mediareplay/media-replay.service';
// import { FooterModule } from './footer/footer.module';
import { LayoutComponent } from './layout.component';
// import { QuickpanelModule } from './quickpanel/quickpanel.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { MaterialModule } from '../../shared/material.module';
import { LayoutRoutingModule } from './layout-routing.module';
import { FabHelperModule } from '../../shared/fab-helper/fab-helper.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MaterialModule,
    LoadingIndicatorModule,
    ToolbarModule,
    // QuickpanelModule,
    SidenavModule,
    // FooterModule,
    BackdropModule,
    FabHelperModule
  ],
  declarations: [LayoutComponent],
  providers: [MediaQueryService]
})
export class LayoutModule {}
