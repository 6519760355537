import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiService } from '../../core';
import { Page, PageFilter } from '../../core/models/Page';
import { User } from './User';

@Injectable()
export class UsersService {
  private path = 'users';

  constructor(private _api: ApiService) {}

  getUsers(params: PageFilter): Observable<Page<User>> {
    return this._api.get<Page<User>>(this.path, params as any);
  }

  getUser(id: number | string): Observable<User> {
    return this._api.get<User>(`${this.path}/${id}`);
  }

  updateUser(user: User) {
    return this._api.put(this.path, user);
  }

  deleteUser(id: number | string) {
    return this._api.delete(`${this.path}/${id}`);
  }

  createUser(user: User): Observable<User> {
    return this._api.post(this.path, user);
  }

  getRoles(): Observable<string[]> {
    return this._api.get(`roles`);
  }

  sendMessage(data): Observable<any> {
    return this._api.post(`${this.path}/helper`, data);
  }
}
