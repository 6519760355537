import { NgModule, APP_INITIALIZER } from '@angular/core';
import { IsRoleDirective } from './isRole.directive';
import { AuthenticationService } from './authentication.service';
import {
  AuthModule,
  AUTH_SERVICE,
  PUBLIC_FALLBACK_PAGE_URI,
  PROTECTED_FALLBACK_PAGE_URI
} from 'ngx-auth';
import { TokenStorage } from './token-storage.service';

@NgModule({
  imports: [AuthModule],
  providers: [
    TokenStorage,
    AuthenticationService,
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
    {
      provide: AUTH_SERVICE,
      deps: [AuthenticationService],
      useFactory: (authService: AuthenticationService) => authService
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthenticationService],
      useFactory: (authService: AuthenticationService) => () =>
        authService.load()
    }
  ],
  declarations: [IsRoleDirective],
  exports: [IsRoleDirective]
})
export class AuthenticationModule {}
