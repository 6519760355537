import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePasswordDialogComponent } from './update-password-dialog.component';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdatePasswordService } from './update-password-dialog.service';

@NgModule({
  declarations: [UpdatePasswordDialogComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [UpdatePasswordService],
  entryComponents: [UpdatePasswordDialogComponent]
})
export class UpdatePasswordModule {}
