import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicGuard } from 'ngx-auth';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth-pages/login/login.module').then(m => m.LoginModule),
    canActivate: [PublicGuard]
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/auth-pages/register/register.module').then(
        m => m.RegisterModule
      ),
    canActivate: [PublicGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/auth-pages/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      ),
    canActivate: [PublicGuard]
  },
  {
    path: 'confirm-invitation',
    loadChildren: () =>
      import('./pages/confim-invitation/confim-invitation.module').then(
        m => m.ConfimInvitationModule
      ),
    canActivate: []
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        m => m.PageNotFoundModule
      ),
    canActivate: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
