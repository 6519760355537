import { Routes } from '@angular/router';
import { ProtectedGuard } from 'ngx-auth';
import { AuthenticationGuard } from '../core/auth/authentication.guard';

// TODO canActivate with roles

export const pagesRoutes: Routes = [
  {
    path: '',
    redirectTo: '/investor-dashboard',
    pathMatch: 'full'
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'projects-future',
    loadChildren: () =>
      import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'projects-closed',
    loadChildren: () =>
      import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'investor-dashboard',
    loadChildren: () =>
      import('./investor-dashboard/investor-dashboard.module').then(
        m => m.InvestorDashboardModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'investor-dashboard-old',
    loadChildren: () =>
      import('./investor-dashboard-old/investor-dashboard-old.module').then(
        m => m.InvestorDashboardOldModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'my-investments',
    loadChildren: () =>
      import('./my-investments/investments.module').then(
        m => m.InvestmentsModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'market',
    loadChildren: () =>
      import('./market/market.module').then(
        m => m.MarketModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then(m => m.AboutModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./faq/faq.module').then(m => m.FaqModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'guide',
    loadChildren: () =>
      import('./guide/guide.module').then(m => m.GuideModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'invite',
    loadChildren: () =>
      import('./invite-user/invite-user.module').then(m => m.InviteUserModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'invitations',
    loadChildren: () =>
      import('./invitations/invitations.module').then(m => m.InvitationsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'admin-projects',
    loadChildren: () =>
      import('./admin-projects/admin-projects.module').then(m => m.AdminProjectsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'new-project',
    loadChildren: () =>
      import('./new-project/new-project.module').then(m => m.NewProjectModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'my-invitations',
    loadChildren: () =>
      import('./my-invitations/my-invitations.module').then(
        m => m.MyInvitationsModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'guest-investments',
    loadChildren: () =>
      import('./guest-investments/guest-investments.module').then(
        m => m.GuestInvestmentsModule
      ),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'investments',
    loadChildren: () =>
      import('./investments/investments.module').then(m => m.InvestmentsModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'new-investment',
    loadChildren: () =>
      import('./new-investment/new-investment.module').then(m => m.NewInvestmentModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'analitics',
    loadChildren: () =>
      import('./analitics/analitics.module').then(
        m => m.AnaliticsModule
      ),
    canActivate: [AuthenticationGuard]
  },
];
