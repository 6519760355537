import { NavigationExtras } from '@angular/router';

export class SidenavItem {
  name: string;
  icon?: string;
  navigationExtras?: NavigationExtras;
  routeOrFunction?: string | ((event: MouseEvent, sidenavItem: SidenavItem) => void);
  parent?: SidenavItem;
  subItems?: SidenavItem[];
  position?: number;
  pathMatchExact?: boolean;
  badge?: string;
  badgeColor?: string;
  type?: 'item' | 'subheading';
  customClass?: string;
  open?: boolean;
  hasRole?: string[];
}
