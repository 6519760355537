// import { AgmCoreModule } from '@agm/core';
import { NgModule, Optional, SkipSelf, LOCALE_ID } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatIconRegistry,
  MatSnackBarConfig
} from '@angular/material';
import { PendingInterceptorModule } from '../shared/loading-indicator/pending-interceptor.module';
import { LayoutModule } from './layout/layout.module';
import { AuthenticationModule } from './auth/authentication.module';
import { UpdatePasswordModule } from '../shared/update-password-dialog/update-password-dialog.module';

@NgModule({
  imports: [
    // Auth Module
    AuthenticationModule,

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    // Google Maps Module
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapsApiKey
    // }),

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,
    UpdatePasswordModule
  ],
  providers: [
    MatIconRegistry,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-419'
    }
  ],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}
