<div
  class="subheading"
  *ngIf="item.type === 'subheading'"
  [class.collapsed]="isCollapsed$ | async"
  [ngClass]="item.customClass"
>
  {{ item.name }}
</div>

<a
  *ngIf="item.type !== 'subheading'"
  class="sidenav-item"
  matRipple
  [ngClass]="levelClass"
  [class.collapsed]="isCollapsed$ | async"
  [class.active]="isActive()"
  (click)="handleClick($event)"
  fxLayout="row"
  fxLayoutAlign="start center"
>

  <div class="menu_letter">
    <p style="text-transform: uppercase; background-color: #3CC34B!important;">{{ item.name[0] }}</p>
    <!--
    <p style="text-transform: uppercase; background-color: #3CC34B!important;" *ngIf="item.name.includes('royecto')">{{ item.name[0] }}</p>
    <p style="text-transform: uppercase; background-color: #F8AD25!important;" *ngIf="item.name.includes('suario')">{{ item.name[0] }}</p>
    <p style="text-transform: uppercase; background-color: #B84848!important;" *ngIf="item.name.includes('nvita')">{{ item.name[0] }}</p>
    <p style="text-transform: uppercase; background-color: #51bda1!important;" *ngIf="item.name.includes('nvers')">{{ item.name[0] }}</p>
    <p style="text-transform: uppercase; background-color: #A151BD!important;" *ngIf="item.name.includes('nforme')">{{ item.name[0] }}</p>
    <p style="text-transform: uppercase; background-color: #407885!important;" *ngIf="item.name.includes('somos')">{{ item.name[0] }}</p>
    -->
  </div>
  <!--
  <mat-icon class="icon" *ngIf="level === 1">{{ item.icon }}</mat-icon>
  -->
  <span class="name">{{ item.name }}</span>
  <span fxFlex><!-- fill space --></span>
  <span
    class="badge"
    *ngIf="item.badge"
    [style.background-color]="item.badgeColor"
    >{{ item.badge }}</span
  >
  
  <mat-icon
    class="expand-indicator"
    *ngIf="item.subItems && item.subItems.length > 0"
    [class.rotate]="(dropdownState$ | async) === 'expanded'"
  >
    expand_more
  </mat-icon>
  
</a>

<div
  class="dropdown"
  fxLayout="column"
  [@dropdownState]="dropdownState$ | async"
>
  <app-sidenav-item
    *ngFor="let subItem of item.subItems"
    [item]="subItem"
    [level]="level + 1"
  ></app-sidenav-item>
</div>
