<div
  class="user-button"
  #userButton
  (appClickOutside)="onClickOutside()"
  fxFlex="grow"
>
  <button
    class="button"
    mat-button
    (click)="toggleDropdown()"
    [class.open]="isOpen"
    fxFlex="grow"
  >
    <span fxLayout="row" fxLayoutAlign="end">
      <!-- <img class="avatar" src="/assets/img/avatars/1.jpg" /> -->
      <mat-icon *ngIf="profileImage == ''" class="avatar">account_circle</mat-icon>
      <div *ngIf="profileImage != ''" class="profile_image_wrapper">
        <img [src]="profileImage">
      </div>

      <ng-container *ngIf="user; else loading">
        <ng-container *ngIf="user.selectedProfile; else elseTemplate">
          <div [ngSwitch]="user.selectedProfile.type">
            <span 
              *ngSwitchCase="profileTypes.business"
              class="name"
              fxHide
              fxShow.gt-sm
            >
              {{ user.selectedProfile.name }}
            </span>
            <span
              *ngSwitchCase="profileTypes.user"
              class="name"
              fxHide
              fxShow.gt-sm
            >
              {{ user.username }}
            </span>

            <mat-icon class="icon" fxHide fxShow.gt-sm
              >keyboard_arrow_down</mat-icon
            >
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <span class="name" fxHide fxShow.gt-sm>
            {{ user.username }}
          </span>
        </ng-template>
      </ng-container>
    </span>
  </button>

  <div class="dropdown elevation" [class.open]="isOpen" style="background-color: white;">
    <div class="content">
      <div class="list">
        <ng-container *ngIf="user; else loading">
          <ng-container *ngIf="user.selectedProfile; else noSelProfList">
            <div
              *ngIf="user; else loading"
              [ngSwitch]="user.selectedProfile.type"
              class="item selectedProfile"
              matRipple
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="10px"
            >
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="icon">account_circle</mat-icon>
                <span *ngSwitchCase="profileTypes.business">
                  {{ user.selectedProfile.name }}
                </span>
                <span *ngSwitchCase="profileTypes.user">{{
                  user.username
                }}</span>
              </div>

              <button mat-raised-button color="primary" (click)="updateProfile(user.selectedProfile.id)">
                Editar Perfil
              </button>
            </div>
            <mat-divider></mat-divider>
            <ng-container *ngIf="user; else loading">
              <div
                *ngFor="let profile of user.profiles"
                [ngSwitch]="profile.type"
                class="item simple"
                matRipple
                (click)="changeProfile(profile)"
              >
                <mat-icon class="icon">account_circle</mat-icon>
                <span *ngSwitchCase="profileTypes.business">
                  {{ profile.name }}
                </span>
                <span *ngSwitchCase="profileTypes.user">{{
                  user.username
                }}</span>
              </div>
            </ng-container>

            <div class="item simple" matRipple (click)="createProfile()">
              <mat-icon class="icon">person_add</mat-icon>
              <span>Crear nuevo perfil de empresa</span>
            </div>
          </ng-container>
          <ng-template #noSelProfList>
            <div class="item simple" matRipple (click)="createUserProfile()">
              <mat-icon class="icon">person_add</mat-icon>
              <span>Crear nuevo perfil personal</span>
            </div>
          </ng-template>
<!--
          <div class="item simple" matRipple>
            <mat-icon class="icon">settings</mat-icon>
            <span>Ajustes</span>
          </div>
          <div class="item simple" matRipple>
            <mat-icon class="icon">help</mat-icon>
            <span>Ayuda</span>
          </div>
-->
          <mat-divider></mat-divider>
          <div class="item simple" matRipple (click)="logout()">
            <mat-icon class="icon">exit_to_app</mat-icon>
            <span>Cerrar sesión</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>loading user...</ng-template>
