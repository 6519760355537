<div
  class="toolbar-notifications"
  (appClickOutside)="onClickOutside()"
  fxFlex="grow"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <button
    class="button"
    type="button"
    mat-button
    (click)="toggleDropdown()"
    [class.open]="isOpen"
    fxFlex="grow"
  >
    <mat-icon
      [matBadge]="notReadNoti"
      matBadgePosition="above after"
      [matBadgeHidden]="!notReadNoti"
      matBadgeColor="warn"
      class="icon"
      >notifications_active</mat-icon
    >
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <app-card>
      <app-card-header class="header">
        <app-card-header-title>
          Notificaciones
        </app-card-header-title>
        <app-card-header-sub-title *ngIf="notifications.length == 1">
          Tienes {{ notifications.length }} nueva notificación.
        </app-card-header-sub-title>
        <app-card-header-sub-title *ngIf="notifications.length > 1">
          Tienes {{ notifications.length }} notificaciones nuevas.
        </app-card-header-sub-title>
      </app-card-header>

      <app-card-content class="card-content">
        <app-scrollbar class="content">
          <div fxLayout="column">
            <ng-container
              *ngFor="let notification of notifications; let last = last"
            >
              <div
                class="notification"
                (click)="markAsRead(notification)"
                [class.is_read]="notification.is_read"
                fxLayout="row"
                fxLayoutAlign="start center"
                matRipple
                [@listFade]
              >
                <mat-icon class="icon">{{ notification.icon }}</mat-icon>
                <div class="label" fxLayout="column">
                  <div *ngIf="notification.is_read == 0" class="name"><strong>{{ notification.title }}</strong></div>
                  <div *ngIf="notification.is_read == 1" class="name">{{ notification.title }}</div>
                  <div *ngIf="notification.is_read == 0"class="time"><strong>{{ notification.name }}</strong></div>
                  <div *ngIf="notification.is_read == 1"class="time">{{ notification.name }}</div>
                </div>
                <span fxFlex></span>
              </div>
              <div *ngIf="!last" class="divider"></div>
            </ng-container>
          </div>
          <div class="empty" *ngIf="notifications.length === 0" [@listFade]>
            No tienes ninguna notificación.
          </div>
        </app-scrollbar>
      </app-card-content>

      <app-card-actions
        class="footer"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div class="action" (click)="markAllAsRead()">Marcar todas como leídas</div>
      </app-card-actions>
    </app-card>
  </div>
</div>
