import { Injectable } from '@angular/core';
import { User, ApiService } from '..';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { ProfileType, Profile } from '../users/user';
import { map, tap, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { UpdatePasswordService } from '../../shared/update-password-dialog/update-password-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User;
  public user$: Subject<User> = new ReplaySubject<User>();
  private path = 'user';

  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private updatePasswordService: UpdatePasswordService
  ) {
    this.authService.authorized$.subscribe({
      next: (isAuth: boolean) => {
        if (isAuth) {
          this.getUserProfile().subscribe();
        }
      }
    });
  }

  getUserProfile(): Observable<User> {
    return this.api.get<User>('user').pipe(
      tap(user => this.setUser(user)),
      tap(user => {

        if (user.update_password) {
          this.showUpdatePassword();
        }
      })
    );
  }

  setUser(user: User) {
    if (user) {
      if (!user.selectedProfile) {
        // assign profile
        user.selectedProfile =
          user.profiles.find(p => p.default) ||
          user.profiles.find(p => p.type === ProfileType.user);
      }

      // rm selectedProfile from profiles
      user.profiles = user.profiles.filter(
        p => p.id !== user.selectedProfile.id
      );
    }

    this._user = user;
    this.user$.next(this._user);
  }

  public changeProfile(profile: Profile) {
    this._user.profiles.push(this._user.selectedProfile);
    this._user.selectedProfile = profile;
    this.setUser(this._user);
  }

  public getUser(): Observable<User> {
    return this.user$.asObservable();
  }

  public getSelectedProfile(): Observable<Profile> {
    return this.getUser().pipe(map(user => user.selectedProfile));
  }

  get user() {
    return this._user;
  }

  public updateUser(newUser: User | any): Observable<User> {
    return this.api
      .put(this.path, newUser)
      .pipe(tap((user: User) => this.setUser(user)));
  }

  public createProfile(profile: Profile): Observable<Profile> {
    return this.api
      .post(`${this.path}/${this._user.id}/profiles`, profile)
      .pipe(tap(() => this.getUserProfile()));
  }

  public updateProfile(profile: Profile): Observable<Profile> {
    return this.api
      .put(`${this.path}/${this._user.id}/profiles`, profile)
      .pipe(tap(() => this.getUserProfile()));
  }

  public hideProfile(id: number): Observable<boolean> {
    return this.api.put(`${this.path}/${id}/profiles/hide`, null);
  }

  private showUpdatePassword() {
    this.updatePasswordService
      .show()
      .pipe(switchMap(password => this.updateUser({ password })))
      .subscribe({ next: () => this.updatePasswordService.close() });
  }

  getProfilePicture(profileId: number) {
    return this.api.get(`${this.path}/${profileId}/picture`);
  }

}
