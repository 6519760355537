import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FabHelperComponent } from './fab-helper.component';
import { Observable } from 'rxjs';

@Injectable()
export class FabHelperService {
  isOpen: boolean;
  dialogRef: MatDialogRef<FabHelperComponent, any>;
  click: Observable<MouseEvent>;


  constructor(public dialog: MatDialog) {
    if (!this.dialogRef) {
      this.show();
    }
  }

  public show(): void {
    this.dialogRef = this.dialog.open(FabHelperComponent, {
      closeOnNavigation: false,
      hasBackdrop: false,
      disableClose: true,
      panelClass: 'fab-helper-dialog-container',
      position: {
        right: '15px',
        bottom: '15px'
      }
    });

    this.click = this.dialogRef.componentInstance.click.asObservable();

  }

  public hide(): void {
    this.dialogRef.componentInstance.click.complete();
    this.click = null;
    this.dialogRef.close();
  }

  public onClick(): Observable<MouseEvent> {
    return this.click;
  }

}
