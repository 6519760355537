<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">

  </div>

  <div fxLayout="row">

    <app-toolbar-notifications class="button"></app-toolbar-notifications>

    <app-toolbar-user-button class="button"></app-toolbar-user-button>



  </div>
</div>
