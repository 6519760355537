import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Roles, User } from '../users';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const roles: Roles[] = route.data['roles'];
    if (!roles) { return true; }
    return this.userService.getUser().pipe(
      map((user: User) => {
        return user && this.findOne(roles, user.roles);
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  /**
   * @description determine if an array contains one or more items from another array.
   * @param haystack the array to search.
   * @param arr the array providing items to check for in the haystack.
   * @return true|false if haystack contains at least one item from arr.
   */
  findOne(arr: any[], arr2: any[]): boolean {
    return arr.some((v) => arr2.indexOf(v) >= 0);
  }
}
