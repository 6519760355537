import { Injectable, Injector, HostListener } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { TokenStorage, Token } from './token-storage.service';
import { ApiService } from '../api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from 'ngx-auth';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

export interface Login {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface RegUser {
  username: string;
  password: string;
  email: string;
}

@Injectable()
export class AuthenticationService implements AuthService {
  private redirectUrl: string;
  public authorized$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private rememberMe: boolean;

  constructor(
    private api: ApiService,
    private tokenStorage: TokenStorage,
    private injector: Injector,
    private dialog: MatDialog
  ) {}

  /**
   * Check, if user already authorized.
   * @description Should return Observable with true or false values
   * @memberOf AuthService
   */
  public isAuthorized(): Observable<boolean> {
    return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
  }

  /**
   * Get access token
   * @description Should return access token in Observable from e.g.
   * localStorage
   */
  public getAccessToken(): Observable<string> {
    return this.tokenStorage.getAccessToken();
  }

  /**
   * Get refresh token
   * @description Should return refresh token in Observable from e.g.
   * localStorage
   */
  public getRefreshToken(): Observable<string> {
    return this.tokenStorage.getRefreshToken();
  }

  beforeunload(ev: BeforeUnloadEvent) {
    if (!this.rememberMe) {
      this.tokenStorage.clear();
    }
  }

  load() {
    return new Promise((resolve, reject) => {
      this.isAuthorized().subscribe({
        next: auth => {
          this.rememberMe = auth;
          this.authorized$.next(auth);
        }
      });
      resolve(true);
    });
  }

  /**
   * Function, login.
   * @description Login
   */
  public login(login: Login): Observable<Token> {
    this.rememberMe = login.remember_me;

    return this.api
      .post<Token>('token', {
        grant_type: 'password',
        email: login.email,
        password: login.password,
        client_id: environment.client_id,
        client_secret: environment.client_secret
      })
      .pipe(
        tap((tokens: Token) => this.tokenStorage.setTokens(tokens)),
        tap(() => this.authorized$.next(true)),
        tap(() => window.addEventListener('beforeunload', this.beforeunload))
      );
  }

  /**
   * Logout
   */
  public logout(): void {
    this.tokenStorage.clear();
    this.dialog.closeAll();
    window.removeEventListener('beforeunload', this.beforeunload);
    this.authorized$.next(false);
    const router = this.injector.get(Router);
    router.navigate([this.getLoginUrl()]);
  }

  /**
   * register
   */
  public register(regUser: RegUser): Observable<any> {
    return this.api.post(`register`, regUser).pipe(
      switchMap(() =>
        this.login({
          email: regUser.email,
          password: regUser.password,
          remember_me: false
        })
      )
    );
  }

  public setRedirectUrl(redirectUrl: string) {
    this.redirectUrl = redirectUrl;
  }

  public getRedirectUrl() {
    return this.redirectUrl;
  }

  public getLoginUrl() {
    return '/login';
  }

  /**
   * Function, refresh access token.
   * @description Refresh access token
   */
  public refreshToken(): Observable<Token> {
    return this.tokenStorage.getRefreshToken().pipe(
      switchMap((refreshToken: string) =>
        this.api.post('token', {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: environment.client_id,
          client_secret: environment.client_secret
        })
      ),
      tap({
        next: (tokens: Token) => this.tokenStorage.setTokens(tokens),
        error: () => this.logout()
      })
    );
  }

  refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  verifyTokenRequest(url: string): boolean {
    return url.endsWith('token');
  }
}
