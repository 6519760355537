import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabHelperComponent } from './fab-helper.component';
import { FabHelperService } from './fab-helper.service';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from '../click-outside/click-outside.module';
import { UsersService } from '../../pages/users/users.service';

@NgModule({
  declarations: [FabHelperComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule
  ],
  providers: [FabHelperService, UsersService],
  entryComponents: [FabHelperComponent]
})
export class FabHelperModule {}
