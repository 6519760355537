import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavService } from '../sidenav.service';
import { SidenavItem } from './sidenav-item.interface';
import * as am4core from "@amcharts/amcharts4/core";

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [
    trigger('dropdownState', [
      state('collapsed', style({
        height: 0
      })),
      state('expanded', style({
        height: '*'
      })),
      transition('collapsed <=> expanded', animate('300ms cubic-bezier(.35, 0, .25, 1)'))
    ])
  ]
})
export class SidenavItemComponent implements OnInit {

  @Input() item: SidenavItem;
  @Input() level: number;

  isCollapsed$: Observable<boolean>;
  dropdownState$: Observable<string>;

  constructor(private sidenavService: SidenavService, private router: Router, private route: ActivatedRoute) {
    this.isCollapsed$ = this.sidenavService.sidenavState$.pipe(
      map(stateSidenav => stateSidenav === 'collapsed')
    );
    this.dropdownState$ = this.sidenavService.currentlyOpen$.pipe(
      map(currentlyOpen => (currentlyOpen.indexOf(this.item) > -1) ? 'expanded' : 'collapsed')
    );
  }

  get levelClass() {
    return `level-${this.level}`;
  }

  ngOnInit() {
    if (this.router.url.indexOf('projects') > -1) {
      const dropdowns = document.querySelectorAll('app-sidenav-item .dropdown');
      for (var i = 0; i < dropdowns.length; i++) {
        (dropdowns[i] as HTMLElement).style.background = '#315015';
      }
    }
  }

  isActive() {
    if (this.item.routeOrFunction && typeof this.item.routeOrFunction !== 'function') {
      if (this.item.navigationExtras != null) {
        let isCorrect = this.router.url.includes(this.item.routeOrFunction);
        if (Object.keys(this.route.snapshot.queryParams).length !==
        Object.keys(this.item.navigationExtras.queryParams).length) {
          return false;
        }
        for (let [key, value] of Object.entries(this.item.navigationExtras.queryParams)) {
          if (!this.router.url.includes(key + '=' + value)) {
            isCorrect = false;
          }
        }
        return isCorrect;
      } else {
        return this.router.url.includes(this.item.routeOrFunction);
      }
    } else {
      return false;
    }
  }

  handleClick($event: MouseEvent) {
    am4core.disposeAllCharts();
    if (this.item.subItems && this.item.subItems.length > 0) {
      this.sidenavService.toggleItemOpen(this.item);
    } else if (typeof this.item.routeOrFunction === 'string' || this.item.routeOrFunction instanceof String) {
      this.router.navigate([this.item.routeOrFunction], this.item.navigationExtras);
    } else if (typeof this.item.routeOrFunction === 'function') {
      this.item.routeOrFunction($event, this.item);
    } else {
      throw Error('Could not determine what to do, Sidenav-Item has no routeOrFunction set AND does not contain any subItems');
    }
  }
}
