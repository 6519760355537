<div (appClickOutside)="clickedOutside()">
  <mat-card *ngIf="showForm" class="contact-form">
    <mat-card-header>
      <mat-card-title>Solicitar cita con un agente</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form">
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px">
          
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="nme" matInput formControlName="name" required/>
          </mat-form-field>
          
          <mat-form-field>
            <mat-label>Teléfono</mat-label>
            <input type="tel" matInput formControlName="phone" required/>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Comentario</mat-label>
            <textarea
              matInput
              formControlName="commentary"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="10"
              required
            ></textarea>
            <mat-error *ngIf="form.get('commentary').invalid">
              {{ getErrorMessage(form.get('commentary')) }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions class="actions">
      <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="sendEmail()">
        Enviar
      </button>
    </mat-card-actions>
  </mat-card>

  <button
    mat-fab
    color="primary"
    (click)="onClick($event)"
    matTooltip="Solicitar cita con un agente"
    matTooltipPosition="left"
    class="toggle-button"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
</div>
