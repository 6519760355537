<app-loading-indicator></app-loading-indicator>

<mat-drawer-container class="layout-container">
  <!-- QUICKPANEL -->
  <!-- <mat-drawer position="end" mode="over" #drawer>
    <app-quickpanel></app-quickpanel>
  </mat-drawer> -->
  <!-- END QUICKPANEL -->

  <mat-drawer-content class="drawer-content">
    <app-backdrop></app-backdrop>

    <div class="wrapper" fxFlex="grow" fxLayout="row wrap">
      <!-- SIDENAV -->
      <app-sidenav class="sidenav" fxFlex="none"></app-sidenav>
      <!-- END SIDENAV -->

      <div class="content-wrapper" fxFlex fxLayout="column" fxFlexFill>
        <!-- TOOLBAR -->
        <app-toolbar fxFlex="none"></app-toolbar>
        <!-- END TOOLBAR -->

        <!-- CONTENT -->
        <div
          class="main-container"
          [@routeAnimations]="
            outlet.isActivated && outlet.activatedRoute.snapshot.url
          "
          fxFlex
        >
          <!-- <div class="scroll-wrapper" #scrollContainer> -->
            <router-outlet
              (activate)="onActivate($event, outlet)"
              #outlet="outlet"
            ></router-outlet>
          <!-- </div> -->
        </div>
        <!-- END CONTENT -->

        <!-- FOOTER -->
        <!-- <app-footer fxFlex="none"></app-footer> -->
        <!-- END FOOTER -->
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
