import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../auth';
import { Router, NavigationExtras } from '@angular/router';
import { User, ProfileType, Profile } from '../../../users/user';
import { MatDialog } from '@angular/material/dialog';
import { NewProfileDialogComponent } from './new-profile-dialog/new-profile-dialog.component';
import { EditProfileDialogComponent } from './edit-profile-dialog/edit-profile-dialog.component';
import { UserService } from '../../../auth/user.service';

@Component({
  selector: 'app-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit {
  isOpen: boolean;
  user: User;
  profileTypes = ProfileType;
  profileImage = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.userService.getUser().subscribe({ next: user => {
      this.user = user;
      this.userService.getProfilePicture(user.selectedProfile.id).subscribe({
        next: image => {
          this.profileImage = image;
        }
      });
    }});
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.closeDropdown();
  }

  closeDropdown() {
    this.isOpen = false;
  }

  openDropdown() {
    this.isOpen = true;
  }

  goTo(commands: any[], extras?: NavigationExtras) {
    this.router.navigate(commands, extras).then(() => this.closeDropdown());
  }

  logout() {
    this.router.navigate(['login']);
    this.authService.logout();
  }

  changeProfile(p: Profile) {
    this.userService.changeProfile(p);
  }

  createProfile() {
    this.closeDropdown();
    const dialogRef = this.dialog.open(NewProfileDialogComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.created) {
        this.openDropdown();
      }
    });
  }

  updateProfile(nothing) {
    this.closeDropdown();
    const dialogRef = this.dialog.open(EditProfileDialogComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.updated) {
        this.openDropdown();
      }
    });
  }

  createUserProfile() {

  }
}
