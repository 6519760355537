import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import { scrollbarOptions } from './scrollbar-options';
import SimpleBar from 'simplebar';

@Component({
  selector: 'app-scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss']
})
export class ScrollbarComponent implements OnInit {
  scrollbarRef: SimpleBar;
  element: ElementRef;

  constructor(private _element: ElementRef, private zone: NgZone) {}

  ngOnInit() {
    this.element = this._element;

    this.zone.runOutsideAngular(() => {
      this.scrollbarRef = new SimpleBar(
        this.element.nativeElement,
        scrollbarOptions
      );
    });
  }
}
