import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdatePasswordDialogComponent } from './update-password-dialog.component';
import { Subject, ReplaySubject } from 'rxjs';

@Injectable()
export class UpdatePasswordService {

  dialogRef: MatDialogRef<UpdatePasswordDialogComponent>;
  event: Subject<string>;

  constructor(private dialog: MatDialog) {}

  show() {

    this.event = new ReplaySubject<string>();

    this.dialogRef = this.dialog.open(UpdatePasswordDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      closeOnNavigation: false,
      data: this.event
    });

    return this.event.asObservable();
    // this.dialogRef.afterClosed();
  }

  close() {
    this.event.complete();
    this.dialogRef.close();
  }


}
