import { AbstractControl } from '@angular/forms';

export function getErrorMessage(control: AbstractControl): string {
  if (control.hasError('required')) {
    return 'Debes ingresar un valor';
  } else if (control.hasError('email')) {
    return 'No es un correo valido';
  } else if (control.hasError('passwordsNotEqual')) {
    return 'Las contraseñas no coinciden';
  } else if (control.hasError('minlength')) {
    return `La longitud mínima es de ${
      control.getError('minlength').requiredLength
    }`;
  } else {
    return '';
  }
}
