import { Component, OnInit, Inject } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormGroupDirective,
  NgForm
} from '@angular/forms';
import { ErrorStateMatcher, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { getErrorMessage } from '../getErrorMessage';
import { UserService } from '../../core/auth/user.service';
import { Subject } from 'rxjs';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-update-password-dialog',
  templateUrl: './update-password-dialog.component.html',
  styleUrls: ['./update-password-dialog.component.scss']
})
export class UpdatePasswordDialogComponent implements OnInit {
  form: FormGroup;
  matcher = new MyErrorStateMatcher();
  getErrorMessage = getErrorMessage;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdatePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Subject<string>
    // private userService: UserService

  ) {
    this.form = this.fb.group(
      {
        password: [null, [Validators.required, Validators.minLength(6)]],
        confirmPassword: [null, Validators.required]
      },
      { validator: this.checkPasswords }
    );
  }

  ngOnInit() {}

  checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { passwordsNotEqual: true };
  }

  updatePassword() {
    const passwords = this.form.getRawValue();
    this.data.next(passwords.password);
  }
}
