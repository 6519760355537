import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCardModule } from '../../../shared/card/card.module';
import { ClickOutsideModule } from '../../../shared/click-outside/click-outside.module';
import { MaterialModule } from '../../../shared/material.module';
import { ScrollbarModule } from '../../../shared/scrollbar/scrollbar.module';
import { ToolbarFullscreenToggleComponent } from './toolbar-fullscreen-toggle/toolbar-fullscreen-toggle.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { ToolbarQuickpanelToggleComponent } from './toolbar-quickpanel-toggle/toolbar-quickpanel-toggle.component';
import { ToolbarSearchBarComponent } from './toolbar-search-bar/toolbar-search-bar.component';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';
import { ToolbarSidenavMobileToggleComponent } from './toolbar-sidenav-mobile-toggle/toolbar-sidenav-mobile-toggle.component';
import { ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar.component';
import { NewProfileDialogComponent } from './toolbar-user-button/new-profile-dialog/new-profile-dialog.component';
import { EditProfileDialogComponent } from './toolbar-user-button/edit-profile-dialog/edit-profile-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AppCardModule
  ],
  declarations: [
    ToolbarComponent,
    ToolbarUserButtonComponent,
    ToolbarNotificationsComponent,
    ToolbarSearchComponent,
    ToolbarSearchBarComponent,
    ToolbarQuickpanelToggleComponent,
    ToolbarFullscreenToggleComponent,
    ToolbarSidenavMobileToggleComponent,
    NewProfileDialogComponent,
    EditProfileDialogComponent
  ],
  exports: [ToolbarComponent],
  entryComponents: [NewProfileDialogComponent, EditProfileDialogComponent]
})
export class ToolbarModule {
}
