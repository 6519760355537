import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface Token {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  refresh_token: string;
}

@Injectable()
export class TokenStorage {
  private accessToken = 'accessToken';
  private refreshToken = 'refreshToken';

  /**
   * Get access token
   */
  public getAccessToken(): Observable<string> {
    return of(localStorage.getItem(this.accessToken));
  }

  /**
   * Get refresh token
   */
  public getRefreshToken(): Observable<string> {
    return of(localStorage.getItem(this.refreshToken));
  }

  /**
   * Set access token
   */
  setAccessToken(accessToken: string): TokenStorage {
    localStorage.setItem(this.accessToken, accessToken);
    return this;
  }

  /**
   * Set access token
   */
  setTokens(tokens: Token): TokenStorage {
    localStorage.setItem(this.accessToken, tokens.access_token);
    localStorage.setItem(this.refreshToken, tokens.refresh_token);
    return this;
  }

  /**
   * Remove token
   */
  public clear() {
    localStorage.removeItem(this.accessToken);
    localStorage.removeItem(this.refreshToken);
    return this;
  }
}
