import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { Roles, User } from '../users';
import { UserService } from './user.service';

@Directive({
  selector: '[appIsRole]'
})
export class IsRoleDirective implements OnInit {
  @Input('appIsRole') roles: Roles[];
  private element: HTMLElement;

  constructor(
    private elRef: ElementRef,
    private userService: UserService
  ) {
    this.element = this.elRef.nativeElement;
  }

  ngOnInit(): void {
    if (this.roles) {
      this.element.style.display = 'none';
      this.userService.getUser().subscribe({
        next: (user: User) => {
          this.element.style.display = this.roles.some(
            role => !user.roles.includes(role)
          )
            ? 'none'
            : 'inherit';
        },
        error: () => {
          this.element.style.display = 'inherit';
        }
      });
    }
  }
}
