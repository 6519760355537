<div
  class="sidenav"
  fxFlex="grow"
  fxLayout="column"
  [@sidenavState]="sidenavState$ | async"
  [class.collapsed]="isCollapsed"
>
  <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
    <img
      [routerLink]="['/']"
      style="height: 70px; margin-left: -20px"
      src="/assets/img/carlton-white.png"
      fxFlexAlign="center"
      class="logo"
    />

    <span fxFlex><!-- fill space --></span>

    <div fxHide fxShow.gt-md fxFlexAlign="center">
      <!-- <button
        class="collapse-toggle"
        *ngIf="!isCollapsedState"
        mat-icon-button
        (click)="toggleCollapsed()"
      >
        <mat-icon class="icon">radio_button_checked</mat-icon>
      </button>
      <button
        class="collapse-toggle"
        *ngIf="isCollapsedState"
        mat-icon-button
        (click)="toggleCollapsed()"
      >
        <mat-icon class="icon">radio_button_unchecked</mat-icon>
      </button> -->
    </div>
  </div>

  <div class="role_menu"
    *ngFor="let role of user.roles"
  >
    <div class="role_selector" (click)="showMenu(role)">
      <div class="role_menu_image_wrapper">
        <div class="role_menu_image_inner_wrapper">
          <mat-icon *ngIf="profileImage == ''" class="avatar">account_circle</mat-icon>
          <div *ngIf="profileImage != ''" class="profile_image_wrapper">
          <img [src]="profileImage">
      </div>
        </div>
      </div>
      <div class="role_menu_info_wrapper">
        <p class="user_name">{{user.first_name}} {{user.last_name}}</p>
        <p *ngIf="role == 'investor'" class="user_role">Inversor</p>
        <p *ngIf="role != 'investor'" class="user_role">{{role}}</p>
      </div>
      <div class="role_menu_icon_wrapper {{role}}">
          <mat-icon class="">
            expand_more
          </mat-icon>
      </div>
    </div>
    <div class="menu_role_wrapper {{role}}">
      <p class="menu_title">MENÚ NAVEGACIÓN</p>
      <app-scrollbar class="sidenav-items" fxFlex>
        <app-sidenav-item
          *ngFor="let item of items$ | async"
          [appIsRole]="item.hasRole"
          [item]="item"
          [level]="1"
        ></app-sidenav-item>
      </app-scrollbar>
    </div>
  </div>
  
</div>
