import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../shared/list.animation';
import { NotificationService, Notification } from '../../../notifications';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit, OnDestroy {
  isOpen: boolean;
  notifications: Notification[];
  notReadNoti: number;
  notSubcription: Subscription;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notSubcription = this.notificationService
      .getNotifications()
      .subscribe(notifications => {
        this.notifications = notifications;
        this.notReadNoti = notifications.filter(noty => noty.is_read === 0).length;
      });
  }

  updateQuantity() {
    this.notReadNoti = this.notifications.filter(noty => noty.is_read === 0).length;
  }

  ngOnDestroy(): void {
    this.notSubcription.unsubscribe();
  }

  dismiss(notification: Notification, event) {
    event.stopPropagation();
    this.notificationService.dismiss(notification);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAsRead(notification: Notification) {
    this.notificationService.markAsRead({ids: [notification.id]}).subscribe();
    notification.is_read = 1;
    this.updateQuantity();
  }

  markAllAsRead() {
    const ids = [];
    this.notifications.forEach(notification => {
      notification.is_read = 1;
      ids.push(notification.id);
    });
    this.notificationService.markAsRead({'ids': ids}).subscribe();
    this.updateQuantity();
  }
}
