<h2 mat-dialog-title>Modifique su contraseña</h2>
<mat-dialog-content>
  <h3>Introduzca aquí su nueva contraseña</h3>

  <form [formGroup]="form">
    <mat-form-field>
      <input
        matInput
        placeholder="Nueva contraseña"
        formControlName="password"
        type="password"
        required
      />
      <mat-error *ngIf="form.get('password').invalid">
        {{ getErrorMessage(form.get('password')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        placeholder="Confirmar contraseña"
        formControlName="confirmPassword"
        type="password"
        [errorStateMatcher]="matcher"
      />
      <mat-error *ngIf="form.get('confirmPassword').invalid">
        {{ getErrorMessage(form.get('confirmPassword')) }}
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    [disabled]="form.invalid"
    (click)="updatePassword()"
  >
    Actualizar
  </button>
</mat-dialog-actions>
