import { Roles } from '.';

export interface User {
  id?: number;
  username: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  dni: string;
  roles?: Roles[];
  profiles: Profile[];
  selectedProfile?: Profile;
}

export enum ProfileType {
  business = 'business',
  user = 'user'
}

export interface IProfile {
  type: ProfileType;
  id: number;
  default?: boolean;
}

export interface UserProfile extends IProfile {
  type: ProfileType.user;
  dni?: string;
  address?: string;
  name?: string;
}


export interface BussinessProfile extends IProfile {
  type: ProfileType.business;
  name?: string;
  cif?: string;
  address?: string;
  phone?: string;
}

export type Profile = UserProfile | BussinessProfile;


