import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  NavigationExtras,
  CanLoad,
  Route,
  UrlSegment
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard
  implements CanActivate, CanActivateChild, CanLoad {
  navigationExtras: NavigationExtras = {
    queryParamsHandling: 'preserve',
    preserveFragment: true
  };

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canRoute(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.canActivate(childRoute, state);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.canRoute(`/${segments[0].path}`);
  }

  private canRoute(redirectUrl: string) {
    return this.authService.isAuthorized().pipe(
      tap(isAuthorized => {
        if (!isAuthorized) {
          this.authService.setRedirectUrl(redirectUrl);
          this.router.navigate(
            [this.authService.getLoginUrl()],
            this.navigationExtras
          );
        }
      }),
      catchError(() => of(false))
    );
  }
}
