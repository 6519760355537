import { Component } from '@angular/core';
import { SidenavItem } from './core/layout/sidenav/sidenav-item/sidenav-item.interface';
import { SidenavService } from './core/layout/sidenav/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(sidenavService: SidenavService) {
    const menu: SidenavItem[] = [
      {
        name: 'Inversor',
        position: 5,
        type: 'subheading',
        customClass: 'first-subheading',
        hasRole: ['investor']
      },
      {
        name: 'Mis Inversiones',
        routeOrFunction: '/my-investments',
        icon: 'chat',
        position: 10,
        hasRole: ['investor'],
        subItems: [
          {
            name: 'Mis inversiones activas',
            routeOrFunction: '/investor-dashboard',
            icon: 'people',
            position: 5,
            hasRole: ['investor']
          },
          {
            name: 'Mis inversiones finalizadas',
            routeOrFunction: '/investor-dashboard-old',
            icon: 'people',
            position: 6,
            hasRole: ['investor']
          },
          {
            name: 'Mis inversiones',
            routeOrFunction: '/my-investments',
            icon: 'people',
            position: 10,
            hasRole: ['investor']
          },
          {
            name: 'Mercado de participaciones',
            routeOrFunction: '/market',
            icon: 'people',
            position: 11,
            hasRole: ['investor']
          }
        ]
      },
      {
        name: 'Proyectos',
        routeOrFunction: '/projects',
        icon: 'dashboard',
        position: 20,
        hasRole: ['investor'],
        subItems: [
          {
            name: 'Históricos',
            routeOrFunction: '/projects-closed',
            navigationExtras: {
              queryParams: {
                state: 'finalized'
              }
            },
            icon: 'people',
            position: 5,
            hasRole: ['investor']
          },
          {
            name: 'Activos',
            routeOrFunction: '/projects',
            navigationExtras: {
              queryParams: {
                state: 'open'
              }
            },
            icon: 'chat',
            position: 10,
            hasRole: ['investor']
          },
          /*
          {
            name: 'Proyectos futuros',
            routeOrFunction: '/projects-future',
            navigationExtras: {
              queryParams: {
                state: 'open',
                future: '1'
              }
            },
            icon: 'dashboard',
            position: 15,
            hasRole: ['investor']
          },
          */
        ]
      },
      {
        name: 'Quiénes somos',
        routeOrFunction: '/about',
        icon: 'people',
        position: 21,
        hasRole: ['investor']
      },
      /*
      {
        name: 'Preguntas frecuentes',
        routeOrFunction: '/faq',
        icon: 'question_answer',
        position: 22,
        hasRole: ['investor']
      },
      {
        name: 'Guía de usuario',
        routeOrFunction: '/guide',
        icon: 'import_contacts',
        position: 24,
        hasRole: ['investor']
      },
      */
      {
        name: 'Colaborador',
        position: 25,
        type: 'subheading',
        customClass: 'first-subheading',
        hasRole: ['collaborator']
      },
      {
        name: 'Proyectos',
        routeOrFunction: '/projects',
        icon: 'dashboard',
        position: 26,
        hasRole: ['collaborator'],
        subItems: [
          {
            name: 'Históricos',
            routeOrFunction: '/projects',
            navigationExtras: {
              queryParams: {
                state: 'finalized'
              }
            },
            icon: 'people',
            position: 5,
            hasRole: ['collaborator']
          },
          {
            name: 'Activos',
            routeOrFunction: '/projects',
            navigationExtras: {
              queryParams: {
                state: 'open'
              }
            },
            icon: 'chat',
            position: 10,
            hasRole: ['collaborator']
          },
          {
            name: 'Proyectos futuros',
            routeOrFunction: '/projects-future',
            navigationExtras: {
              queryParams: {
                state: 'open',
                future: '1'
              }
            },
            icon: 'dashboard',
            position: 15,
            hasRole: ['collaborator']
          },
        ]
      },
      {
        name: 'Invitar comercial / inversor',
        routeOrFunction: '/invite',
        icon: 'person_add',
        position: 30,
        hasRole: ['collaborator']
      },
      {
        name: 'Mis invitaciones',
        routeOrFunction: '/my-invitations',
        icon: 'chat',
        position: 32,
        hasRole: ['collaborator']
      },
      {
        name: 'Inversiones de invitados',
        routeOrFunction: '/guest-investments',
        icon: 'chat',
        position: 35,
        hasRole: ['collaborator']
      },
      {
        name: 'Quiénes somos',
        routeOrFunction: '/about',
        icon: 'people',
        position: 36,
        hasRole: ['collaborator']
      },
      {
        name: 'Comercial',
        position: 37,
        type: 'subheading',
        customClass: 'first-subheading',
        hasRole: ['comercial']
      },
      {
        name: 'Proyectos',
        routeOrFunction: '/projects',
        icon: 'dashboard',
        position: 38,
        hasRole: ['comercial'],
        subItems: [
          {
            name: 'Históricos',
            routeOrFunction: '/projects',
            navigationExtras: {
              queryParams: {
                state: 'finalized'
              }
            },
            icon: 'people',
            position: 5,
            hasRole: ['comercial']
          },
          {
            name: 'Activos',
            routeOrFunction: '/projects',
            navigationExtras: {
              queryParams: {
                state: 'open'
              }
            },
            icon: 'chat',
            position: 10,
            hasRole: ['comercial']
          },
          {
            name: 'Proyectos futuros',
            routeOrFunction: '/projects-future',
            navigationExtras: {
              queryParams: {
                state: 'open',
                future: '1'
              }
            },
            icon: 'dashboard',
            position: 15,
            hasRole: ['comercial']
          },
        ]
      },
      {
        name: 'Invitar inversor',
        routeOrFunction: '/invite',
        icon: 'person_add',
        position: 39,
        hasRole: ['comercial']
      },
      {
        name: 'Mis invitaciones',
        routeOrFunction: '/my-invitations',
        icon: 'chat',
        position: 40,
        hasRole: ['comercial']
      },
      {
        name: 'Inversiones de invitados',
        routeOrFunction: '/guest-investments',
        icon: 'chat',
        position: 41,
        hasRole: ['comercial']
      },
      {
        name: 'Quiénes somos',
        routeOrFunction: '/about',
        icon: 'people',
        position: 42,
        hasRole: ['comercial']
      },
      {
        name: 'Administrador',
        position: 43,
        type: 'subheading',
        customClass: 'first-subheading',
        hasRole: ['admin']
      },
      {
        name: 'Proyectos',
        routeOrFunction: '/admin-projects',
        icon: 'house',
        position: 45,
        hasRole: ['admin']
      },
/*
      {
        name: 'Nuevo proyecto',
        routeOrFunction: '/new-project',
        icon: 'add_box',
        position: 45,
        hasRole: ['admin']
      },
*/
      {
        name: 'Usuarios',
        routeOrFunction: '/users',
        icon: 'people',
        position: 60,
        hasRole: ['admin']
      },
      {
        name: 'Invitaciones',
        routeOrFunction: '/invitations',
        icon: 'insert_invitation',
        position: 65,
        hasRole: ['admin']
      },
      {
        name: 'Inversiones',
        routeOrFunction: '/investments',
        icon: 'insert_invitation',
        position: 70,
        hasRole: ['admin']
      },
      {
        name: 'Informes i analítica',
        routeOrFunction: '/analitics',
        icon: 'dashboard',
        position: 75,
        hasRole: ['admin']
      }
    ];
    sidenavService.addItems(menu);
  }
}
