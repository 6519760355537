<h2 mat-dialog-title>Editar perfil</h2>
<mat-dialog-content class="mat-typography">
  <h3>Inserte los datos para editar el perfil</h3>

  <!-- USER PROFILE FORM -->
  <form *ngIf="selectedProfileType == 'user'" [formGroup]="userProfile" (ngSubmit)="onSubmit()">

    <mat-form-field class="invisible">
      <mat-label>Id</mat-label>
      <input matInput type="text" formControlName="id" required/>
      <mat-error *ngIf="userProfile.get('id').invalid">
        {{ getErrorMessage(userProfile.get('id')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput type="text" formControlName="first_name" required />
      <mat-error *ngIf="userProfile.get('first_name').invalid">
        {{ getErrorMessage(userProfile.get('first_name')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Apellidos</mat-label>
      <input matInput type="text" formControlName="last_name" required />
      <mat-error *ngIf="userProfile.get('last_name').invalid">
        {{ getErrorMessage(userProfile.get('last_name')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>D.N.I.</mat-label>
      <input matInput type="text" formControlName="dni" required />
      <mat-error *ngIf="userProfile.get('dni').invalid">
        {{ getErrorMessage(userProfile.get('dni')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dirección</mat-label>
      <input matInput type="text" formControlName="address" required />
      <mat-error *ngIf="userProfile.get('address').invalid">
        {{ getErrorMessage(userProfile.get('address')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input matInput type="text" formControlName="email" required />
      <mat-error *ngIf="userProfile.get('email').invalid">
        {{ getErrorMessage(userProfile.get('email')) }}
      </mat-error>
    </mat-form-field>

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">        

      <div fxFlex>
        <mat-label style='display: block; font-size: 12px; color: #666;'>Imagen</mat-label>
        <input type="file" formControlName="image" value="Escoger imagen" (change)="onFileChange($event)" />
        <mat-error *ngIf="userProfile.get('image').invalid">
            {{ getErrorMessage(userProfile.get('image')) }}
        </mat-error>
      </div>

      <div fxFlex class="mt-30 profilePictureWraper aa" *ngIf="userProfile.get('image').value == 'not-modified'">
        <img class="profilePicture" [src]="userProfile.get('picture').value">
      </div>
      <div fxFlex class="mt-30 profilePictureWraper" *ngIf="userProfile.get('image').value != 'not-modified'">
        <img class="profilePicture" [src]="userProfile.get('image').value">
      </div>

    </div>
  </form>

  <!-- BUSINESS PROFILE FORM -->
  <form *ngIf="selectedProfileType == 'business'" [formGroup]="companyProfile" (ngSubmit)="onSubmit()">
    <mat-form-field class="invisible">
      <mat-label>Id</mat-label>
      <input matInput type="text" formControlName="id" required/>
      <mat-error *ngIf="companyProfile.get('id').invalid">
        {{ getErrorMessage(companyProfile.get('id')) }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput type="text" formControlName="name" required />
      <mat-error *ngIf="companyProfile.get('name').invalid">
        {{ getErrorMessage(companyProfile.get('name')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Cif</mat-label>
      <input matInput type="text" formControlName="cif" required />
      <mat-error *ngIf="companyProfile.get('cif').invalid">
        {{ getErrorMessage(companyProfile.get('cif')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dirección</mat-label>
      <input matInput type="text" formControlName="address" required />
      <mat-error *ngIf="companyProfile.get('address').invalid">
        {{ getErrorMessage(companyProfile.get('address')) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Teléfono</mat-label>
      <input matInput type="text" formControlName="phone" required />
      <mat-error *ngIf="companyProfile.get('phone').invalid">
        {{ getErrorMessage(companyProfile.get('phone')) }}
      </mat-error>
    </mat-form-field>

    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">        

      <div fxFlex>
        <mat-label style='display: block; font-size: 12px; color: #666;'>Imagen</mat-label>
        <input type="file" formControlName="image" value="Escoger imagen" (change)="onFileChange($event)" />
        <mat-error *ngIf="companyProfile.get('image').invalid">
            {{ getErrorMessage(companyProfile.get('image')) }}
        </mat-error>
      </div>

      <div fxFlex class="mt-30 profilePictureWraper aa" *ngIf="companyProfile.get('image').value == 'not-modified'">
        <img class="profilePicture" [src]="companyProfile.get('picture').value">
      </div>
      <div fxFlex class="mt-30 profilePictureWraper" *ngIf="companyProfile.get('image').value != 'not-modified'">
        <img class="profilePicture" [src]="companyProfile.get('image').value">
      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button *ngIf="selectedProfileType == 'user'" mat-raised-button (click)="onSubmit()" cdkFocusInitial color="primary" [disabled]=userProfile.invalid>
    Editar
  </button>
  <button *ngIf="selectedProfileType == 'business'" mat-raised-button (click)="onSubmit()" cdkFocusInitial color="primary" [disabled]=companyProfile.invalid>
    Editar
  </button>
  <button *ngIf="selectedProfileType == 'business'" mat-raised-button (click)="onDelete()" cdkFocusInitial color="danger">
    Eliminar
  </button>
</mat-dialog-actions>
