import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Notification } from './Notification';
import { ApiService } from '..';
import { array } from '@amcharts/amcharts4/core';

const notifications: Notification[] = [];

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications$: Subject<Notification[]> = new BehaviorSubject([]);
  private path = 'user';

  constructor(private api: ApiService) {
    this.notifications$.next(notifications);
  }

  getNotifications() {
    return this.api.get(`${this.path}/notifications`);
  }

  dismiss(notification: Notification): void {
    notifications.splice(notifications.indexOf(notification), 1);
    this.notifications$.next(notifications);
  }

  markAsRead(ids: any) {
    return this.api.put(`${this.path}/notifications`, ids);
  }

  private _getNotifications() {
    // http
  }

}
