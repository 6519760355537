import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User, ProfileType, Profile } from '../../../../users/user';
import { getErrorMessage } from '../../../../../shared/getErrorMessage';
import { UserService } from '../../../../auth/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss']
})

export class EditProfileDialogComponent implements OnInit {

  userProfile: FormGroup;
  companyProfile: FormGroup;
  profileType = ProfileType;

  user: User;
  selectedProfileType: string;

  getErrorMessage = getErrorMessage;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditProfileDialogComponent>
  ) {

    this.userProfile = this.fb.group({
      id: [null, Validators.required],
      type: [ProfileType.user, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      dni: [null, Validators.required],
      address: [null, Validators.required],
      email: [null, Validators.required],
      image: [null],
      picture: [null]
    });
    this.userProfile.patchValue({
      image: 'not-modified'
    });

    this.companyProfile = this.fb.group({
      id: [null, Validators.required],
      type: [ProfileType.business, Validators.required],
      name: [null, Validators.required],
      cif: [null, Validators.required],
      address: [null, Validators.required],
      phone: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern("^[0-9]*$")]],
      image: [null],
      picture: [null]
    });
    this.companyProfile.patchValue({
      image: 'not-modified'
    });
}

  ngOnInit() {
    this.userService.getUser().subscribe({ next: user => (this.user = user) });
    this.userService.getSelectedProfile().subscribe({ next: p => ( this.initDialog(p) ) });
  }

  initDialog(selectedProfile){

    this.selectedProfileType = selectedProfile.type;

    switch( selectedProfile.type ){
      case "user":
        this.userProfile.patchValue({
          id: selectedProfile.id,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          dni: selectedProfile.dni,
          address: selectedProfile.address,
          email: this.user.email
        });
      break;
      case "business":
        this.companyProfile.patchValue({
          id: selectedProfile.id,
          name: selectedProfile.name,
          cif: selectedProfile.cif,
          address: selectedProfile.address,
          phone: selectedProfile.phone
        });
      break;
    }

    this.userService.getProfilePicture(selectedProfile.id).subscribe(
      r => {

        switch(this.selectedProfileType){
          case "user":
            this.userProfile.patchValue({
              picture: r
            });
          break;
          case "business":
            this.companyProfile.patchValue({
              picture: r
            });
          break;
        }
      }
    );

  }

  onSubmit() {

    let profile: Profile;

    switch( this.selectedProfileType ){
      case "user":
        if (this.userProfile.invalid) { return; }
        profile = this.userProfile.getRawValue();
      break;
      case "business":
        if (this.companyProfile.invalid) { return; }
        profile = this.companyProfile.getRawValue();
      break;
    }

    this.userService.updateProfile(profile).subscribe({
      next: (data) => {
        this.openSnackBar('Perfil actualizado.');
        this.dialogRef.close({created: true});

        switch( this.selectedProfileType ){
          case "user":
            this.userService.getUserProfile().subscribe();
          break;
          case "business":
            this.userService.changeProfile(data);
          break;
        }
      },
      error: () => this.openSnackBar('Error al editar perfil.')
    });
  }

  onDelete() {
    const profile: Profile = this.companyProfile.getRawValue();
    this.userService.hideProfile(profile.id).subscribe({
      next: () => {
        this.openSnackBar('Perfil eliminado.');
        this.dialogRef.close({created: true});
        this.userService.getUserProfile().subscribe({ next: user => (this.user = user) });
      },
      error: () => this.openSnackBar('Error al eliminar perfil.')
    });
  }

  openSnackBar(message: string, action = 'OK') {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];

      switch(this.selectedProfileType){
        case "user":
          this.userProfile.patchValue({
            picture: file.name
          });
        break;
        case "business":
          this.companyProfile.patchValue({
            picture: file.name
          });
        break;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        switch(this.selectedProfileType){
          case "user":
            this.userProfile.patchValue({
              image: reader.result
            });
          break;
          case "business":
            this.companyProfile.patchValue({
              image: reader.result
            });
          break;
        }
      };
    }
  }
}
